

















































import BaseCard from "@/components/Base/BaseCard.vue";
import BaseForm from "@/components/Base/BaseForm.vue";
import BaseWrapper from "@/components/Base/BaseWrapper.vue";
import { maxFileSize } from "@/helpers/InputRule";
import { Component, Vue } from "vue-property-decorator";

@Component({
    components: {
        BaseForm,
        BaseWrapper,
        BaseCard,
    },
})
export default class AppProposalNew extends Vue {
    public formData: any = {};
    public currentFocus: string = "";
    public isFormValid: boolean = false;

    get meRole() {
        return this.$store.getters["users/meRole"];
    }

    get categories() {
        return this.$store.getters["categories/sortedCategories"];
    }
    get types() {
        return this.$store.getters["types/sortedTypes"];
    }
    get typesWithoutAppplication() {
        const excludeName: string = "APLIKASI";

        return this.$store.getters["types/sortedTypes"].filter((type: any) => {
            return type.name !== excludeName;
        });
    }

    get forms() {
        let forms: any[] = [];

        if (this.categories) {
            forms = [
                {
                    text: "Tambahkan cover proposal",
                    value: "cover",
                    type: "file",
                    fileType: "image",
                    rules: [...maxFileSize(2)],
                },
                {
                    text: "Judul proposal baru...",
                    value: "title",
                    type: "title",
                    required: true,
                    helperTitle: "Menulis Judul Proposal yang Hebat",
                    helperDescription: `Pikirkan judul proposal Anda sebagai deskripsi yang sangat pendek (tapi menarik!) - seperti ringkasan proposal sebenarnya dalam satu kalimat pendek.
                        Gunakan kata kunci jika sesuai untuk membantu memastikan orang dapat menemukan posting Anda melalui pencarian.`,
                },

                {
                    text: "Pilih kategori proposal...",
                    value: "category",
                    type: "autocomplete",
                    hint: "Contoh entertain, pembangunan, dsb...",
                    items: this.categories,
                    itemText: "name",
                    itemValue: "id",
                    returnObject: true,
                    required: true,
                    helperTitle: "Pemilihan Kategori",
                    helperDescription: `Kategori membantu orang menemukan proposal Anda.
                        Pikirkan kategori sebagai topik yang paling menggambarkan proposal Anda.`,
                },

                {
                    text: "Tulis deskripsi proposal disini...",
                    value: "description",
                    type: "description",
                    required: true,
                    helperTitle: "Deskripsi Proposal",
                    helperDescription: `
                        Tulis deskripsi proposal anda`,
                },
                {
                    text: "Dana yang diperlukan",
                    value: "required_fund",
                    required: true,
                    isSpecial: true,
                    type: "currency",
                    prefix: "Rp",
                    helperTitle: "Dana yang diperlukan",
                    helperDescription: `
                        Isi dana yang anda perlukan untuk proposal`,
                },
                {
                    text: "Upload File Proposal",
                    value: "proposal",
                    type: "file",
                    fileType: "pdf",
                    required: true,
                    rules: [...maxFileSize(2)],
                },
            ];

            if (this.meRole === "COMPANY") {
                forms.unshift({
                    text: "Tipe proposal",
                    placeholder: "Tambahkan tipe proposal",
                    value: "type",
                    type: "combobox",
                    items: this.typesWithoutAppplication,
                    itemText: "name",
                    itemValue: "id",
                    returnObject: true,
                    required: true,
                    helperTitle: "Tipe proposal",
                    helperDescription: `
                        Pilih INISIATIF jika proposal untuk perusahaan atau karyawan sendiri,
                        pilih LANGSUNG jika proposal dari masyarakat atau organisasi lain.`,
                });
            }

            if (this.meRole === "PROPOSER") {
                forms.push({
                    text: "Tanggal Expired",
                    placeholder: "Tanggal berakhir proposal / kegiatan",
                    value: "expired_at",
                    type: "date",
                    min: this.minExpiredDate,
                    max: this.maxExpiredDate,
                    required: true,
                    withLabel: true,
                    helperTitle: "Tanggal expired proposal",
                    helperDescription: `
                        Proposal anda akan dinonaktifkan jika lewat dari tanggal expired`,
                });
            }
        }

        return forms;
    }

    setFocus(input: any) {
        this.currentFocus = input;
    }

    formValidation(valid: boolean) {
        this.isFormValid = valid;
    }

    get maxExpiredDate() {
        let today = new Date();
        let maxExpiredDate = new Date(today.setMonth(today.getMonth() + 3));

        return maxExpiredDate.toISOString().substr(0, 10);
    }

    get minExpiredDate() {
        let today = new Date();

        return today.toISOString().substr(0, 10);
    }

    dummy() {
        this.formData = {
            title: "Some title A",
            type:
                this.meRole === "COMPANY"
                    ? this.typesWithoutAppplication[0]
                    : null,
            category: this.categories[0],
            description: "Lorem ipsum dolor sir amet.",
            required_fund: 15000000,
            // expired_at: this.maxExpiredDate,
        };
    }

    async addProposal() {
        const findApplicationType = this.types.filter((type: any) => {
            return type.name === "APLIKASI";
        })[0];

        if (this.meRole === "PROPOSER")
            this.formData.type = findApplicationType;

        if (this.formData.expired_at)
            this.formData.expired_at = new Date(this.formData.expired_at);

        console.log("Proposal:", this.formData);

        const response = await this.$store.dispatch(
            "proposals/addProposal",
            this.formData
        );

        const isSuccess = response && response.proposal;

        if (isSuccess)
            this.$router.push("/app/proposal/" + response.proposal.id);
    }

    get helperTitle() {
        if (!this.currentFocus) return null;
        let title: string = "";

        const findForm = this.forms.filter((form: any) => {
            return form.value === this.currentFocus;
        });

        if (findForm) title = findForm[0].helperTitle;

        return title;
    }

    get helperDescription() {
        if (!this.currentFocus) return null;
        let description: string = "";

        const findForm = this.forms.filter((form: any) => {
            return form.value === this.currentFocus;
        });

        if (findForm) description = findForm[0].helperDescription;

        return description;
    }
}
